@import 'tailwindcss/base';

@import 'tailwindcss/components';

@import 'tailwindcss/utilities';

body {
  margin: 0;
  padding: 0;
}

.maindiv {
  scrollbar-width: none;
  width: 100%;
  margin: 0 auto;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
}
.maindiv::-webkit-scrollbar {
  display: none;
}
html::-webkit-scrollbar {
  display: none;
}
.panel {
  scroll-snap-align: start;
}
